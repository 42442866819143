import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "../../../../ui-treact/components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../../../../ui-treact/components/misc/Buttons.js";
import EmailIllustrationSrc from "../../../../ui-treact/images/email-illustration.svg";

const Container = tw.div`relative w-full bg-white`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.text_on_left ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.img_src}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-800`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`

export default ({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
        sub_heading: "Contact Us",
        heading: (
            <>Feel free to <span tw="text-primary-500">get in touch</span> with us.</>
          ),
        description: "Thank you for visiting our site, We are a full-service marketing firm with a passion for a radical open mind and radical transparency. No surprises, no gimmicks, just the bottom line. <br /> If you are interested in any of our services or would like to schedule a consultation, please contact us on the contact form.",
        submit_button_text:"Contact Us",

        ...finalData
  } 

  // The text_on_left boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image img_src={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn text_on_left={content.text_on_left}>
          <TextContent>
            {content.sub_heading && <Subheading>{content.sub_heading}</Subheading>}
            <Heading>{content.heading}</Heading>
            <Description dangerouslySetInnerHTML={{ __html: content.description }}></Description>
            <Form action={content.form_action} method={content.form_method}>
              <Input type="email" name="email" placeholder="Your Email Address" />
              <SubmitButton type="submit">{content.submit_button_text}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
